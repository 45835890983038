<template>
  <div class="bg-white rounded-lg shadow-sm p-4">
    <h4 class="font-heading text-dark font-weight-bold">
      {{ $t('sparkmembers.contents.views.lives.test-transmission.title') }}
    </h4>
    <p class="transmission-description my-3">
      {{ $t('sparkmembers.contents.views.lives.test-transmission.description') }}
    </p>
    <MButton
      :label="$t('sparkmembers.contents.views.lives.test-transmission.test-btn')"
      variant="primary-outline"
      @click="testTransmission"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MButton from '@/shared/components/MButton.vue';

export default {
  components: {
    MButton,
  },
  props: {
    product: {
      type: Object,
    },
  },
  computed: {
    ...mapState('course', {
      course: state => state.selectedCourse,
    }),
  },
  methods: {
    testTransmission() {
      let route = this.$router.resolve({
        name: 'LiveMeetTestRoom',
        query: { id: this.product.id, content: this.course.id },
      });
      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.font-heading {
  font-size: 1.25rem;
}
.transmission-description {
  font-size: 0.874rem;
  color: #6f6f6f;
}
</style>
